import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { motion, AnimatePresence } from 'framer-motion';
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import { Bot, User } from 'lucide-react';
import { MdSend, MdSettingsVoice } from "react-icons/md";
import ReactMarkdown from 'react-markdown';
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { FaVolumeUp } from "react-icons/fa";

const YOU = "you";
const AI = "ai";

function ChatBot({ user }) {
  // State Management
  const [translatedText, setTranslatedText] = useState("");
  const [translateState, setTranslateState] = useState(false);
  const { transcript, listening, resetTranscript } = useSpeechRecognition();
  const [inputValue, setInputValue] = useState("");
  const [qna, setQna] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("");

  // Keywords for topic detection
  const healthKeywords = [
    "heart", "cardiac", "cardiovascular", "blood pressure", "hypertension",
    "cholesterol", "arrhythmia", "palpitations", "chest pain", "angina",
    "heart rate", "pulse", "ECG", "EKG", "heart attack", "stroke",
    "cardiology", "heart disease", "heart failure", "diabetes", "sugar",
    "insulin", "metformin", "blood sugar", "diabetic", "type 1", "type 2"
  ];

  useEffect(() => {
    if (qna.length === 0) {
      setQna([{
        from: AI,
        value: `👋 Hello${user?.name ? ' ' + user.name : ''}!  its great to see you 👋`,
        timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
      }]);
    }
  }, [user]);

  // Translation function
  const translate = async (language, translateText) => {
    try {
      setSelectedLanguage(language);
      setLoading(true);
      const response = await axios.post("https://api.chanrephysio.com/chat", {
        message: `Translate this text to ${language}: ${translateText} `
      });

      setTranslatedText(response.data.response);
      setTranslateState(true);
    } catch (error) {
      console.error('Translation error:', error);
      toast.error('Translation failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Message handling
  const updateQNA = (from, value) => {
    const timestamp = new Date().toLocaleTimeString([], { 
      hour: '2-digit', 
      minute: '2-digit' 
    });
    setQna(prev => [...prev, { from, value, timestamp }]);
  };

  const handleSend = async () => {
    if (!inputValue?.trim()) {
      toast.info("Please write something!");
      return;
    }

    // Check if question contains health-related keywords
    const isHealthRelated = healthKeywords.some(keyword => 
      inputValue.toLowerCase().includes(keyword.toLowerCase())
    );

    // if (!isHealthRelated) {
    //   toast.info('Please ask about health-related topics.');
    //   return;
    // }

    // Send message
    updateQNA(YOU, inputValue);
    setInputValue(""); // Clear input
    setTranslatedText("");
    setTranslateState(false);
    setLoading(true);

    try {
      const response = await axios.post("https://api.chanrephysio.com/chat", {
        message: inputValue + 'explain in short',
      });

      updateQNA(AI, response.data.response);
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to send message. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Voice recording
  const startRecord = () => {
    SpeechRecognition.startListening();
  };

  const stopRecord = () => {
    SpeechRecognition.stopListening();
    if (transcript) {
      setInputValue(transcript);
    }
  };

  return (
    <div className="z-50 relative min-h-screen bg-gradient-to-b from-red-50 to-white">
      <div className="mx-auto w-full ">
        {/* Chat Container */}
        <div className=" bg-white shadow-lg overflow-hidden">
          {/* Chat Header */}
          {/* <div className="border-b bg-white p-4">
            <div className="flex items-center gap-3">
              <div className="rounded-full bg-red-100 p-2">
                <Bot className="h-5 w-5 text-red-600" />
              </div>
              <div>
                <h2 className="font-semibold text-gray-900">Health Assistant</h2>
                <div className="flex items-center gap-2">
                  <span className="h-2 w-2 rounded-full bg-green-500"></span>
                  <span className="text-sm text-gray-500">Online</span>
                </div>
              </div>
            </div>
          </div> */}

          {/* Messages Area */}
          <div className="h-[70vh] space-y-4 overflow-y-auto scroll-smooth p-4">
            <AnimatePresence>
              {qna.map((item, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  className={`flex ${item.from === YOU ? 'justify-end' : 'justify-start'}`}
                >
                  <div className={`flex max-w-[80%] items-start gap-3 ${
                    item.from === YOU ? 'flex-row-reverse' : ''
                  }`}>
                    <div className={`rounded-full p-2 ${
                      item.from === YOU ? 'bg-red-600 text-white' : 'bg-gray-100'
                    }`}>
                      {item.from === YOU 
                        ? <User className="h-4 w-4" />
                        : <Bot className="h-4 w-4 text-red-600" />
                      }
                    </div>
                    <div className={`rounded-2xl p-4 ${
                      item.from === YOU ? 'bg-red-600 text-white' : 'bg-gray-100'
                    }`}>
                      <ReactMarkdown className="prose max-w-none text-sm">
                        {item.value}
                      </ReactMarkdown>
                      <div className={`mt-2 text-xs ${
                        item.from === YOU ? 'text-red-100' : 'text-gray-500'
                      }`}>
                        {item.timestamp}
                      </div>

                      {/* Language */}
                      {item.from === AI && (
  <div className="mt-2 flex gap-2 text-xs text-gray-500 flex-wrap">
    {/* Hindi */}
    <button
      onClick={() => translate("Hindi", item.value)}
      className={`text-red-600 hover:underline ${
        selectedLanguage === "Hindi" ? "font-bold" : ""
      }`}
      disabled={loading}
    >
      Hindi
    </button>
    <span>|</span>

    {/* Kannada */}
    <button
      onClick={() => translate("Kannada", item.value)}
      className={`text-red-600 hover:underline ${
        selectedLanguage === "Kannada" ? "font-bold" : ""
      }`}
      disabled={loading}
    >
      Kannada
    </button>
    <span>|</span>

    {/* Bengali */}
    <button
      onClick={() => translate("Bengali", item.value)}
      className={`text-red-600 hover:underline ${
        selectedLanguage === "Bengali" ? "font-bold" : ""
      }`}
      disabled={loading}
    >
      Bengali
    </button>
    <span>|</span>

    {/* Tamil */}
    <button
      onClick={() => translate("Tamil", item.value)}
      className={`text-red-600 hover:underline ${
        selectedLanguage === "Tamil" ? "font-bold" : ""
      }`}
      disabled={loading}
    >
      Tamil
    </button>
    <span>|</span>

    {/* Telugu */}
    <button
      onClick={() => translate("Telugu", item.value)}
      className={`text-red-600 hover:underline ${
        selectedLanguage === "Telugu" ? "font-bold" : ""
      }`}
      disabled={loading}
    >
      Telugu
    </button>
    <span>|</span>

    {/* Malayalam */}
    <button
      onClick={() => translate("Malayalam", item.value)}
      className={`text-red-600 hover:underline ${
        selectedLanguage === "Malayalam" ? "font-bold" : ""
      }`}
      disabled={loading}
    >
      Malayalam
    </button>

    <FaVolumeUp
      className="text-gray-500 cursor-pointer hover:text-gray-700 ml-2"
      title="Listen"
      onClick={() => {
        const utterance = new SpeechSynthesisUtterance(item.value);
        utterance.lang = "en-US"; // You can customize the language code
        speechSynthesis.speak(utterance);
      }}
    />

  </div>
)}


                      {/* Language */}
                       
                    </div>
                  </div>
                </motion.div>
              ))}

              {translateState && translatedText && (
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  className="flex justify-start"
                >
                  <div className="flex max-w-[80%] items-start gap-3">
                    <div className="rounded-full bg-gray-100 p-2">
                      <Bot className="h-4 w-4 text-red-600" />
                    </div>
                    <div className="rounded-2xl bg-gray-100 p-4">
                      <ReactMarkdown className="prose max-w-none text-sm">
                        {translatedText}
                      </ReactMarkdown>
                      <div className="mt-2 text-xs text-gray-500">
                        Translated to {selectedLanguage}
                      </div>
                    </div>
                  </div>
                </motion.div>
              )}

              {loading && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="flex items-center gap-2"
                >
                  <div className="rounded-full bg-gray-100 p-2">
                    <Bot className="h-4 w-4 text-red-600" />
                  </div>
                  <div className="flex gap-1">
                    {[0, 1, 2].map((i) => (
                      <motion.div
                        key={i}
                        className="h-2 w-2 rounded-full bg-red-600"
                        animate={{ scale: [1, 1.2, 1] }}
                        transition={{
                          repeat: Infinity,
                          duration: 1,
                          delay: i * 0.2
                        }}
                      />
                    ))}
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          {/* Input Area */}
          <div className="border-t bg-white p-4">
            <div className="flex items-center gap-2">
              <button
                onTouchStart={startRecord}
                onMouseDown={startRecord}
                onTouchEnd={stopRecord}
                onMouseUp={stopRecord}
                className="rounded-full p-2 hover:bg-gray-100"
                title={listening ? "Stop recording" : "Start recording"}
              >
                <MdSettingsVoice 
                  className={listening ? "text-red-600" : "text-gray-400"}
                  size={20}
                />
              </button>
              <input
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder="Ask about health..."
                className="w-full rounded-xl border border-gray-200 p-3 focus:border-red-600 focus:outline-none focus:ring-2 focus:ring-red-100"
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    handleSend();
                  }
                }}
              />
              <motion.button
                onClick={handleSend}
                disabled={loading || !inputValue.trim()}
                className={`rounded-xl bg-red-600 p-3 text-white transition-all ${
                  loading || !inputValue.trim() ? 'cursor-not-allowed opacity-50' : 'hover:bg-red-700'
                }`}
                whileHover={{ scale: loading || !inputValue.trim() ? 1 : 1.05 }}
                whileTap={{ scale: loading || !inputValue.trim() ? 1 : 0.95 }}
                title="Send message"
              >
                <MdSend size={20} />
              </motion.button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-center" theme="colored" />
    </div>
  );
}

export default ChatBot;