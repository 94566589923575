import React, { useEffect, useState } from 'react';
import NewNavbar from "../Components/Common/NewNavbar";
import NewFooter from "../Components/Common/NewFooter";
import Copyright from "../Components/FirstComponents/Copyright";
import Ads from "../Components/FirstComponents/Ads";
import Subscribe from "../Components/FirstComponents/Subscribe";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Banner from "../Utils/Banner";
import {
  Send,
  Loader,
  Info,
  Printer,
  RotateCcw,
  Sun,
  Moon,
  Coffee,
  UtensilsCrossed,
  Apple,
  Beef,
  Fish,
  Wheat,
  Leaf,
  Heart,
  Activity,
  Kidney,
  HeartIcon, Download, FileImage, FilePdf
} from 'lucide-react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import ReactMarkdown from 'react-markdown';
import { FaFilePdf } from 'react-icons/fa';



export default function Dietchart() {
  // State Management
  const [calories, setCalories] = useState('');
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [darkMode, setDarkMode] = useState(false);
  const [dietaryPreferences, setDietaryPreferences] = useState([]);
  const [mealsPerDay, setMealsPerDay] = useState(3);
  const [step, setStep] = useState(1);
  const [selectedState, setSelectedState] = useState('');
  const [selectedAge, setSelectedAge] = useState('');
  const [healthCondition, setHealthCondition] = useState('');


  const [downloadType, setDownloadType] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);

  // Add these download functions
  const downloadAsImage = async () => {
    try {
      setIsDownloading(true);
      setDownloadType('image');

      const element = document.getElementById('meal-plan-content');
      if (!element) return;

      const canvas = await html2canvas(element, {
        backgroundColor: darkMode ? '#1F2937' : '#FFFFFF',
        scale: 2, // Higher resolution
      });

      const link = document.createElement('a');
      link.download = `diet-plan-${new Date().toLocaleDateString().replace(/\//g, '-')}.png`;
      link.href = canvas.toDataURL('image/png');
      link.click();
    } catch (error) {
      console.error('Error downloading image:', error);
    } finally {
      setIsDownloading(false);
      setDownloadType(null);
    }
  };

  const downloadAsPDF = async () => {
    try {
      setIsDownloading(true);
      setDownloadType('pdf');
  
      const element = document.getElementById('meal-plan-content');
      if (!element) return;
  
      // Force the text color to be visible in PDF
      const elementsToStyle = element.getElementsByTagName('*');
      const originalStyles = [];
  
      // Store original styles and apply temporary ones
      for (let el of elementsToStyle) {
        originalStyles.push({
          element: el,
          color: el.style.color,
          backgroundColor: el.style.backgroundColor,
          fontSize: el.style.fontSize
        });
  
        if (darkMode) {
          el.style.color = '#FFFFFF';
        } else {
          el.style.color = '#000000';
        }
      }
  
      const canvas = await html2canvas(element, {
        backgroundColor: darkMode ? '#1F2937' : '#FFFFFF',
        scale: 2,
        useCORS: true,
        logging: false,
        letterRendering: true,
        width: element.offsetWidth,
        height: element.offsetHeight,
        onclone: (clonedDoc) => {
          const clonedElement = clonedDoc.getElementById('meal-plan-content');
          // Apply any specific styles to the cloned element if needed
          clonedElement.style.padding = '20px';
        }
      });
  
      // Restore original styles
      originalStyles.forEach(({ element, color, backgroundColor, fontSize }) => {
        element.style.color = color;
        element.style.backgroundColor = backgroundColor;
        element.style.fontSize = fontSize;
      });
  
      const imgData = canvas.toDataURL('image/png', 1.0);
  
      // Create PDF with the same aspect ratio as the content
      const aspectRatio = canvas.height / canvas.width;
      const pdfWidth = 210; // A4 width in mm
      const pdfHeight = pdfWidth * aspectRatio;
  
      const pdf = new jsPDF({
        orientation: pdfHeight > pdfWidth ? 'portrait' : 'landscape',
        unit: 'mm',
        format: [pdfWidth, pdfHeight]
      });
  
      // Add the image at full page size
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight, '', 'FAST');
  
      // Add a subtle page number at the bottom
      const pageCount = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        pdf.setPage(i);
        pdf.setFontSize(8);
        pdf.setTextColor(100, 100, 100);
        pdf.text(
          `Page ${i} of ${pageCount}`,
          pdfWidth / 2,
          pdfHeight - 5,
          { align: 'center' }
        );
      }
  
      pdf.save(`diet-plan-${new Date().toLocaleDateString().replace(/\//g, '-')}.pdf`);
    } catch (error) {
      console.error('Error downloading PDF:', error);
    } finally {
      setIsDownloading(false);
      setDownloadType(null);
    }
  };
  // Constants
  const presetCalories = [    
    { label: '', value: 1800 },
    { label: '', value: 1200 },
    { label: '', value: 1500 },
    { label: '', value: 2000 }
  ];

  const dietaryOptions = [
    // { label: 'Diabetes-Friendly', icon: Heart, value: 'diabetes-friendly' },
    { label: 'Vegetarian', icon: Leaf, value: 'vegetarian' },
    { label: 'Non vegetarain', icon: Beef, value: 'high-protein' },
    { label: 'Low Carb', icon: Wheat, value: 'low-carb' },
    { label: 'Pescatarian', icon: Fish, value: 'pescatarian' }
  ];

  const stateOptions = [
    'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 
  'Chhattisgarh', 'Goa', 'Gujarat', 'Haryana', 
  'Himachal Pradesh', 'Jharkhand', 'Karnataka', 'Kerala', 
  'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 
  'Mizoram', 'Nagaland', 'Odisha', 'Punjab', 
  'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 
  'Tripura', 'Uttar Pradesh', 'Uttarakhand', 'West Bengal'
  ];

  const ageGroups = [
    { label: 'Teens (13-19)', value: 'teens' },
    { label: 'Young Adults (20-35)', value: 'young-adults' },
    { label: 'Adults (36-50)', value: 'adults' },
    { label: 'Seniors (51+)', value: 'seniors' }
  ];

  const healthConditions = [
    {
      label: 'Diabetes',
      value: 'diabetes',
      description: 'Low glycemic index foods',
      icon: Heart,
      guidelines: 'Focus on low GI foods, regular meals'
    },
    {
      label: 'Blood Pressure',
      value: 'blood-pressure',
      description: 'Low sodium diet',
      icon: Activity,
      guidelines: 'Reduce sodium, increase potassium'
    },
    {
      label: 'Kidney Problem',
      value: 'kidney',
      description: 'Low protein & mineral diet',
      icon: HeartIcon,
      guidelines: 'Monitor protein, potassium, phosphorus'
    }
  ];

  const nutritionFacts = [
    "Did you know? Protein helps keep you feeling full longer!",
    "Tip: Drinking water before meals can help with portion control.",
    "Fun fact: Eating slowly helps with better digestion.",
    "Tip: Colors on your plate indicate variety in nutrients!"
  ];

  // Validation Function
  const validateCalories = (value) => {
    const trimmedValue = value.trim();

    if (!trimmedValue) {
      return { isValid: false, error: 'Please enter the number of calories' };
    }

    const calorieNum = Number(trimmedValue);

    if (isNaN(calorieNum) || calorieNum <= 0) {
      return { isValid: false, error: 'Please enter a valid number of calories' };
    }

    if (calorieNum < 1200) {
      return { isValid: false, error: 'Daily calories should be at least 1200 for safety' };
    }

    if (calorieNum > 10000) {
      return { isValid: false, error: 'Please enter a reasonable calorie goal (max 10000)' };
    }

    return { isValid: true, value: calorieNum };
  };

  // Event Handlers
  const handleCalorieChange = (e) => {
    const value = e.target.value;
    if (value === '' || /^\d+$/.test(value)) {
      setCalories(value);
      setError('');
    }
  };

  const toggleDietaryPreference = (preference) => {
    setDietaryPreferences(prev =>
      prev.includes(preference)
        ? prev.filter(p => p !== preference)
        : [...prev, preference]
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const validation = validateCalories(calories);

    // if (!validation.isValid) {
    //   setError(validation.error);
    //   return;
    // }

    setError('');
    setLoading(true);
    setStep(2);

    try {
      const dietaryStr = dietaryPreferences.length
        ? `following ${dietaryPreferences.join(', ')} preferences`
        : '';

      const stateStr = selectedState
        ? `considering ${selectedState} regional cuisine`
        : '';

      const ageStr = selectedAge
        ? `for ${selectedAge} age group`
        : '';

      const healthStr = healthCondition
        ? `with special consideration for ${healthCondition} condition. ${healthConditions.find(h => h.value === healthCondition)?.guidelines || ''
        }`
        : '';

      const response = await axios.post(`https://api.chanrephysio.com/chat`, {
        message: `What indian food should I eat throughout the day to maintain, ${dietaryStr} ${stateStr} ${ageStr} ${healthStr} divided into ${mealsPerDay} meals per day? Please include approximate calorie counts for each meal and any specific dietary considerations for the health condition.`
      });

      setResponse(response.data.response);
      setStep(3);
    } catch (error) {
      console.error('Error:', error);
      setError('Failed to get meal plan. Please try again.');
      setStep(1);
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    setCalories('');
    setResponse('');
    setError('');
    setStep(1);
    setDietaryPreferences([]);
    setMealsPerDay(3);
    setSelectedState('');
    setSelectedAge('');
    setHealthCondition('');
  };

  const handlePrint = () => {
    window.print();
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);

  // Component Sections
  const ProgressSteps = () => (
    <div className="mb-4 flex justify-center">
      <div className="flex items-center">
        {[1, 2, 3].map((s) => (
          <div key={s} className="flex items-center">
            <div
              className={`flex h-8 w-8 items-center justify-center rounded-full ${s === step
                ? 'bg-blue-600 text-white'
                : s < step
                  ? 'bg-green-500 text-white'
                  : darkMode
                    ? 'bg-gray-700 text-gray-300'
                    : 'bg-gray-200 text-gray-600'
                }`}
            >
              {s}
            </div>
            {s < 3 && (
              <div
                className={`h-0.5 w-8 ${s < step
                  ? 'bg-green-500'
                  : darkMode
                    ? 'bg-gray-700'
                    : 'bg-gray-200'
                  }`}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );

  const DietaryPreferencesSection = () => (
    <div className="mb-6">
      <h3 className={`mb-3 text-center text-sm lg:text-sm font-medium ${darkMode ? 'text-white' : 'text-gray-700'
        }`}>
        Dietary Preferences
      </h3>
      <div className="flex flex-wrap justify-center gap-3 text-xs lg:text-sm">
        {dietaryOptions.map(option => (
          <motion.button
            key={option.value}
            onClick={() => toggleDietaryPreference(option.value)}
            className={`flex items-center gap-2 rounded-lg px-4 py-2 ${dietaryPreferences.includes(option.value)
              ? 'bg-blue-600 text-white'
              : darkMode
                ? 'bg-gray-800 text-white hover:bg-gray-700'
                : 'bg-white text-gray-800 hover:bg-gray-50'
              } shadow-md transition-colors`}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <option.icon className="h-4 w-4" />
            {option.label}
          </motion.button>
        ))}
      </div>
    </div>
  );

  const StateSelector = () => (
    <div className="mb-6">
      <h3 className={`mb-3 text-center text-sm lg:text-sm font-medium ${darkMode ? 'text-white' : 'text-gray-700'
        }`}>
        Select Your State (Optional)
      </h3>
      <div className="flex justify-center">
        <select
          value={selectedState}
          onChange={(e) => setSelectedState(e.target.value)}
          className={`w-full max-w-md rounded-lg border p-2 text-sm ${darkMode
            ? 'border-gray-600 bg-gray-800 text-white'
            : 'border-gray-300 bg-white text-gray-800'
            } focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-200`}
        >
          <option value="">Select a state</option>
          {stateOptions.map((state) => (
            <option key={state} value={state}>
              {state}
            </option>
          ))}
        </select>
      </div>
    </div>
  );

  const AgeGroupSelector = () => (
    <div className="mb-6">
      <h3 className={`mb-3 text-center text-sm lg:text-sm font-medium ${darkMode ? 'text-white' : 'text-gray-700'
        }`}>
        Select Age Group (Optional)
      </h3>
      <div className="flex flex-wrap justify-center gap-3 text-xs lg:text-sm">
        {ageGroups.map(age => (
          <motion.button
            key={age.value}
            onClick={() => setSelectedAge(selectedAge === age.value ? '' : age.value)}
            className={`rounded-lg px-4 py-2 ${selectedAge === age.value
              ? 'bg-blue-600 text-white'
              : darkMode
                ? 'bg-gray-800 text-white hover:bg-gray-700'
                : 'bg-white text-gray-800 hover:bg-gray-50'
              } shadow-md transition-colors`}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            {age.label}
          </motion.button>
        ))}
      </div>
    </div>
  );

  const HealthConditionSelector = () => (
    <div className="mb-6">
      <h3 className={`mb-3 text-center text-sm lg:text-sm font-medium ${darkMode ? 'text-white' : 'text-gray-700'
        }`}>
        Health Condition (If Any)
      </h3>
      <div className="flex flex-wrap justify-center gap-4">
        {healthConditions.map(condition => (
          <motion.button
            key={condition.value}
            onClick={() => setHealthCondition(healthCondition === condition.value ? '' : condition.value)}
            className={`relative flex flex-col items-center rounded-lg p-4 ${healthCondition === condition.value
              ? 'bg-blue-600 text-white'
              : darkMode
                ? 'bg-gray-800 text-white hover:bg-gray-700'
                : 'bg-white text-gray-800 hover:bg-gray-50'
              } shadow-md transition-colors min-w-[160px]`}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <condition.icon className={`h-6 w-6 mb-2`} />
            <span className="font-medium mb-1">{condition.label}</span>
            <span className={`text-xs ${healthCondition === condition.value ? 'text-white/80' : 'text-gray-500'
              }`}>
              {condition.description}
            </span>
            {healthCondition === condition.value && (
              <div className="absolute -top-1 -right-1 bg-green-500 rounded-full p-1">
                <svg className="w-3 h-3 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
              </div>
            )}
          </motion.button>
        ))}
      </div>
    </div>
  );

  const MealsPerDaySection = () => (
    <div className="mb-6">
      <h3 className={`mb-3 text-center text-sm lg:text-lg font-medium ${darkMode ? 'text-white' : 'text-gray-700'
        }`}>
        Meals per Day
      </h3>
      <div className="flex justify-center gap-4">
        {[3, 4, 5, 6].map(num => (
          <motion.button
            key={num}
            onClick={() => setMealsPerDay(num)}
            className={`rounded-lg px-6 py-2 text-xs lg:text-sm ${mealsPerDay === num
              ? 'bg-blue-600 text-white'
              : darkMode
                ? 'bg-gray-800 text-white hover:bg-gray-700'
                : 'bg-white text-gray-800 hover:bg-gray-50'
              } shadow-md transition-colors`}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            {num}
          </motion.button>
        ))}
      </div>
    </div>
  );

  return (
    <div
      className="bg-contain bg-no-repeat bg-gray-200"
      style={{
        backgroundImage: "url('/assets/background.png')",
        backgroundPosition: "right top",
      }}
    >
      <NewNavbar />
      <Banner title="DIET CHART" imageUrl="/assets/Second_Banner.png" />

      <div className="mx-auto ml-4 mr-4 md:ml-6 md:mr-6 lg:mr-14 lg:ml-14 xl:ml-32 xl:mr-32">
        <div className={`min-h-screen transition-colors duration-300 ${darkMode ? 'bg-gray-900 text-white' : ''
          }`}>
          <div className="mx-auto max-w-4xl px-2 py-10">
            {/* Theme Toggle */}
            <div className="absolute right-4 top-4">
              <motion.button
                onClick={() => setDarkMode(!darkMode)}
                className={`rounded-full p-2 ${darkMode ? 'bg-gray-800 text-yellow-500' : 'bg-white text-gray-800'
                  }`}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                {darkMode ? <Sun className="h-5 w-5" /> : <Moon className="h-5 w-5" />}
              </motion.button>
            </div>

            <ProgressSteps />

            {/* Header */}
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              className="mb-8 text-center"
            >
              <h1 className={`mb-2 text-xl lg:text-3xl font-bold ${darkMode ? 'text-white' : 'text-gray-900'
                }`}>
                Daily Meal Planner
              </h1>
              <p className={darkMode ? 'text-gray-300' : 'text-gray-600 text-sm'}>
                Get personalized meal suggestions based on your preferences
              </p>
            </motion.div>

            {/* Preset Calorie Buttons */}
            {/* <div className="mb-6 flex flex-wrap justify-center gap-4">
              {presetCalories.map(preset => (
                <motion.button
                  key={preset.value}
                  onClick={() => setCalories(preset.value.toString())}
                  className={`rounded-lg px-4 py-2 text-xs lg:text-sm ${calories === preset.value.toString()
                    ? 'bg-blue-600 text-white'
                    : darkMode
                      ? 'bg-gray-800 text-white hover:bg-gray-700'
                      : 'bg-white text-gray-800 hover:bg-gray-50'
                    } shadow-md transition-colors`}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  {preset.label} {preset.value} kcal
                </motion.button>
              ))}
            </div> */}

            {/* All Filter Sections */}
            <HealthConditionSelector />
            <DietaryPreferencesSection />
            <StateSelector />
            <AgeGroupSelector />
            <MealsPerDaySection />

            {/* Input Form */}
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              className="mb-8"
            >
              <form onSubmit={handleSubmit} className="flex flex-col items-center gap-4">
                <div className="w-full max-w-md text-xs lg:text-sm">
                  <label
                    htmlFor="calories"
                    className={`mb-2 block text-sm lg:text-lg font-medium ${darkMode ? 'text-white' : 'text-gray-700'
                      }`}
                  >
                    Enter Your Daily Calorie Goal
                  </label>
                  <div className="flex gap-2">
                    <div className="relative w-full">
                      <input
                        id="calories"
                        type="text"
                        inputMode="numeric"
                        pattern="\d*"
                        value={calories}
                        onChange={handleCalorieChange}
                        placeholder="e.g. 1800"
                        className={`w-full rounded-lg border p-3 pr-12 transition-colors ${darkMode
                          ? 'border-gray-600 bg-gray-800 text-white placeholder-gray-400 focus:border-blue-500'
                          : 'border-gray-300 focus:border-blue-500'
                          } focus:outline-none focus:ring-2 focus:ring-blue-200`}
                      />
                      <span className={`absolute right-3 top-1/2 -translate-y-1/2 ${darkMode ? 'text-gray-400' : 'text-gray-500'
                        }`}>
                        kcal
                      </span>
                    </div>
                    <motion.button
                      type="submit"
                      // disabled={loading || !calories.trim()}
                      className={`rounded-lg bg-blue-600 px-6 py-3 text-white transition-all ${loading || !calories.trim()
                        ? 'cursor-not-allowed opacity-50'
                        : 'hover:bg-blue-700'
                        }`}
                      whileHover={{ scale: loading || !calories.trim() ? 1 : 1.05 }}
                      whileTap={{ scale: loading || !calories.trim() ? 1 : 0.95 }}
                    >
                      {loading ? (
                        <Loader className="h-5 w-5 animate-spin" />
                      ) : (
                        <Send className="h-5 w-5" />
                      )}
                    </motion.button>
                  </div>
                  <AnimatePresence>
                    {error && (
                      <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0 }}
                        className="mt-2 flex items-center gap-2 text-sm text-red-500"
                      >
                        <Info className="h-4 w-4" />
                        {error}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </form>
            </motion.div>

            {/* Results Display */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="relative"
            >
              <div id="meal-plan-content" className={`relative rounded-lg px-4 py-8 border w-full ${darkMode ? 'bg-gray-800' : 'bg-white'
                }`}>
                {/* Decorative Elements */}
                <div className={`absolute left-4 top-0 h-3 w-20 -translate-y-1/2 rounded-t-lg ${darkMode ? 'bg-gray-600' : 'bg-gray-200'
                  }`}></div>
                <div className={`absolute right-4 top-0 h-3 w-20 -translate-y-1/2 rounded-t-lg ${darkMode ? 'bg-gray-600' : 'bg-gray-200'
                  }`}></div>

                {/* Content */}
                <div className="min-h-[400px]">
                  {loading ? (
                    <div className="flex h-full flex-col items-center justify-center gap-4">
                      <Loader className={`h-8 w-8 animate-spin ${darkMode ? 'text-blue-400' : 'text-blue-600'
                        }`} />
                      <p className={`text-center text-sm ${darkMode ? 'text-gray-400' : 'text-gray-600'
                        }`}>
                        {nutritionFacts[Math.floor(Math.random() * nutritionFacts.length)]}
                      </p>
                    </div>
                  ) : response ? (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      className={`max-w-none text-sm lg:text-lg ${darkMode ? 'text-gray-200' : 'text-gray-800'
                        }`}
                    >
                      <ReactMarkdown
                        components={{
                          strong: ({ node, ...props }) => (
                            <span className={`font-bold ${darkMode ? 'text-blue-300' : 'text-blue-700'
                              }`} {...props} />
                          ),
                          p: ({ node, ...props }) => (
                            <p className="mb-4" {...props} />
                          ),
                          ul: ({ node, ...props }) => (
                            <ul className="mb-4 list-inside list-disc space-y-2" {...props} />
                          ),
                          li: ({ node, ...props }) => (
                            <li className="ml-4" {...props} />
                          )
                        }}
                      >
                        {response}
                      </ReactMarkdown>

                      {/* Action Buttons */}
                      <div className="mt-6 flex justify-end gap-3 print:hidden">
                        <motion.button
                          onClick={handleReset}
                          className={`flex items-center gap-2 rounded-lg px-4 py-2 text-sm ${darkMode
                            ? 'border-gray-700 bg-gray-800 text-gray-300 hover:bg-gray-700'
                            : 'border border-gray-300 text-gray-600 hover:bg-gray-50'
                            }`}
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                        >
                          <RotateCcw className="h-4 w-4" />
                          New Plan
                        </motion.button>
                        <motion.button
                          onClick={downloadAsImage}
                          disabled={isDownloading}
                          className={`flex items-center gap-2 rounded-lg px-4 py-2 text-sm ${darkMode
                            ? 'bg-blue-900 text-blue-100 hover:bg-blue-800'
                            : 'bg-blue-100 text-blue-700 hover:bg-blue-200'
                            } ${isDownloading && downloadType === 'image' ? 'opacity-50 cursor-not-allowed' : ''}`}
                          whileHover={{ scale: isDownloading ? 1 : 1.05 }}
                          whileTap={{ scale: isDownloading ? 1 : 0.95 }}
                        >
                          {isDownloading && downloadType === 'image' ? (
                            <Loader className="h-4 w-4 animate-spin" />
                          ) : (
                            <FileImage className="h-4 w-4" />
                          )}
                          {isDownloading && downloadType === 'image' ? 'Downloading...' : 'Save as Image'}
                        </motion.button>

                        <motion.button
                          onClick={downloadAsPDF}
                          disabled={isDownloading}
                          className={`flex items-center gap-2 rounded-lg px-4 py-2 text-sm ${darkMode
                            ? 'bg-blue-900 text-blue-100 hover:bg-blue-800'
                            : 'bg-blue-100 text-blue-700 hover:bg-blue-200'
                            } ${isDownloading && downloadType === 'pdf' ? 'opacity-50 cursor-not-allowed' : ''}`}
                          whileHover={{ scale: isDownloading ? 1 : 1.05 }}
                          whileTap={{ scale: isDownloading ? 1 : 0.95 }}
                        >
                          {isDownloading && downloadType === 'pdf' ? (
                            <Loader className="h-4 w-4 animate-spin" />
                          ) : (
                            <FaFilePdf className="h-4 w-4" />
                          )}
                          {isDownloading && downloadType === 'pdf' ? 'Downloading...' : 'Save as PDF'}
                        </motion.button>
                      </div>
                    </motion.div>
                  ) : (
                    <div className={`flex h-full flex-col items-center justify-center gap-4 ${darkMode ? 'text-gray-400' : 'text-gray-400'
                      }`}>
                      <UtensilsCrossed className="h-12 w-12 opacity-50" />
                      <p className="text-center">
                        Enter your calorie goal to get a personalized meal plan
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </motion.div>
          </div>
        </div>

        {/* <Ads /> */}
        {/* <Subscribe /> */}
      </div>
      <NewFooter />
      <Copyright />
    </div>
  );
}