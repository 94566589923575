import { useState } from "react";
import ChatBot from "./components/ChatBot";
import ChatRegForm from "./components/ChatRegForm";
import { MdClose } from "react-icons/md";

function MainChatBot({ setIsChatOn }) {
  const initialValue = { name: "", email: "", phone: "", state: "" };
  const [user, setUser] = useState(initialValue);
  const [login, setLogin] = useState(false);

  return (
    <div className='main-container '>
      <div className='headre  '>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className='header_icon_container'>
            <img src='../assets/zuca.jpeg' alt='icon' className='icon' />
          </div>
          <div className='header_text_con'>
            <span className='headerText'>Mitra</span>
            <span className='subText'>AI-Based Chat-Bot</span>
          </div>
          <div className="flex items-center gap-2">
            {/* <span className="h-2 w-2 rounded-full bg-green-500"></span>
            <span className="text-sm text-gray-500">Online</span> */}
          </div>
        </div>
        <div className='close_btn' onClick={() => setIsChatOn(false)}>
          <MdClose />
        </div>
      </div>
      {login ? (
        <ChatBot user={user} />
      ) : (
        <ChatRegForm setLogin={setLogin} user={user} setUser={setUser} />
      )}
    </div>
  );
}

export default MainChatBot;





// import { useState } from "react";
// import ChatBot from "./components/ChatBot";
// import ChatRegForm from "./components/ChatRegForm";
// import { MdClose } from "react-icons/md";

// function MainChatBot({ setIsChatOn }) {
//   const initialValue = { name: "", email: "", phone: "", state: "" };
//   const [user, setUser] = useState(initialValue);
//   const [login, setLogin] = useState(false);

//   return (
//     <div className='main-container '>
//       <div className='headre  '>
//         <div style={{ display: "flex", alignItems: "center" }}>
//           <div className='header_icon_container'>
//             <img src='../assets/zuca.jpeg' alt='icon' className='icon' />
//           </div>
//           <div className='header_text_con'>
//             <span className='headerText'>Mitra</span>
//             <span className='subText'>AI-Based Chat-Bot</span>
//           </div>
//         </div>
//         <div className='close_btn' onClick={() => setIsChatOn(false)}>
//           <MdClose />
//         </div>
//       </div>
//       {login ? (
//         <ChatBot user={user} />
//       ) : (
//         <ChatRegForm setLogin={setLogin} user={user} setUser={setUser} />
//       )}
//     </div>
//   );
// }

// export default MainChatBot;
